import React from "react"
import Logo from "./Logo"
import { Link } from "gatsby"

const Footer = () => {
    return (
        <footer className="footer-pad text-center footer-section">
            <div className="container-fluid container-pad text-left">
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="text-left">
                            <Logo className="mb-3" light />
                            <p>
                                We are Strategically Innovative, Technologically
                                Advanced, Globally-Present, Socially Active,
                                Truly Original and quite Ingenious. Billiontags
                                is a creative factory! Connect with us.
                            </p>
                        </div>
                        <div className="d-flex">
                            <a
                                href="https://www.facebook.com/billiontags"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="color-white mr-3"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                >
                                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                </svg>
                            </a>
                            <a
                                href="https://twitter.com/billiontags"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="color-white mr-3"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                >
                                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                </svg>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/billion-tags"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="color-white mr-3"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                >
                                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                    <rect x={2} y={9} width={4} height={12} />
                                    <circle cx={4} cy={4} r={2} />
                                </svg>
                            </a>
                            <a
                                href="mailto:official@billiontags.com"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="color-white mr-3"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                >
                                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                    <polyline points="22,6 12,13 2,6" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="d-flex">
                            <div className="mr-2 d-none d-md-block">
                                <svg
                                    viewBox="0 0 24 24"
                                    width={24}
                                    height={24}
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                >
                                    <circle cx={12} cy={12} r={10} />
                                    <polyline points="12 16 16 12 12 8" />
                                    <line x1={8} y1={12} x2={16} y2={12} />
                                </svg>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-weight-normal">
                                    <Link to="/">HOME</Link>
                                </h5>
                                <p className="mb-3">Welcome to Billion tags!</p>
                                <h5 className="font-weight-normal">
                                    <Link to="/company">COMPANY</Link>
                                </h5>
                                <p className="mb-3">
                                    Get to know us and the work ethic that
                                    drives us.
                                </p>
                                <h5 className="font-weight-normal">
                                    <Link to="/strategy">STRATEGY</Link>
                                </h5>
                                <p className="mb-3">
                                    Don't judge us by what we say; judge us by
                                    our work!
                                </p>
                                {/* <h5 className="font-weight-normal">
                                    <Link to="/branding" className="color-white">
                                        BRANDING
                                    </Link>
                                </h5>
                                <p className="mb-3">
                                    Lorem ipsum dolor sit amet harum
                                    <br />
                                    distinctio ipsa libero
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="d-flex">
                            <div className="flex-1">
                                <h5 className="font-weight-normal">
                                    <Link to="/contact" className="color-white">
                                        CONTACT US
                                    </Link>
                                </h5>
                                <p className="mb-3">
                                    Want to work with us? Got a project you want
                                    us to handle? Get in touch!
                                </p>
                                <h5 className="font-weight-normal">
                                    <Link to="/contact">Address</Link>
                                </h5>
                                <p className="mb-3">
                                    New Door No.782, Old No.427, 1st Floor, Anna
                                    Salai, Nandanam, Chennai, Tamil Nadu - 600
                                    035.
                                </p>
                                <p className="mb-3">
                                    <a
                                        href="mailto:official@billiontags.com"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="color-white mr-3"
                                    >
                                        <svg
                                            viewBox="0 0 24 24"
                                            width={24}
                                            height={24}
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="css-i6dzq1"
                                        >
                                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                            <polyline points="22,6 12,13 2,6" />
                                        </svg>
                                        <span className="pl-2">
                                            hey@billiontags.com
                                        </span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="footer-bottom f-16 mt-5">
                            Copyrights © 2025 Billiontags | Digital Marketing
                            Company | All rights reserve
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
