import React from "react"
import styles from "../../css/products.module.css"

const ParallaxHeader = ({ title = "Our Products" }) => {
    return (
        <section id="page-title" className={styles.parallaxHeader}>
            <div className="container">{title}</div>
        </section>
    )
}

export default ParallaxHeader
