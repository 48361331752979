import React from "react"
import Layout from "../components/common/Layout"
import ParallaxHeader from "../components/products/ParallaxHeader"
import "../css/font-icons.css"

const Terms = () => {
    return (
        <Layout>
            <ParallaxHeader title="Terms and Conditions" />
            <div class="py-5 container">
                <p>
                    <span>
                        These online terms and conditions (“Online T&amp;Cs”)
                        supplement the applicable Insertion Order entered by the
                        Company and Billiontags.
                    </span>
                </p>
                <p>
                    <span>The Company and Billiontags may hereinafter be</span>
                    <strong>“Party”</strong>
                    <span> and collectively referred to as the </span>
                    <strong>“Parties”</strong>
                    <span>.</span>
                </p>
                <ol style={{ lineHeight: "30px" }}>
                    <li>
                        <strong> DEFINITIONS</strong>
                        <p>
                            <span>
                                In these Online T&amp;Cs, including the
                                recitals, the following words shall have the
                                following meanings:
                            </span>
                        </p>
                        <p>
                            <strong>1.1. “Ad Inventory”</strong>
                            <span>
                                means advertising impressions served through a
                                digital medium, including, without limitation,
                                on browsers, applications, TVs, and Out of Home
                                platforms. For clarity, this will also include
                                online advertising impressions bought on Search
                                and Social Media platforms.
                            </span>
                        </p>
                        <p>
                            <strong>1.2. “Creative”</strong>
                            <span> means a unit of advertising content.</span>
                        </p>
                        <p>
                            <strong>1.3. “Affiliate”</strong>
                            <span>
                                means, with respect to a Party, an entity that
                                directly or indirectly controls, is controlled
                                by or is under common control with such Party.
                            </span>
                        </p>
                        <p>
                            <strong>1.4. “Client”</strong>
                            <span>
                                means such advertiser, network or other third
                                party, if any, on whose behalf Company utilises
                                a Service and shall include the
                                successors-in-interest and permitted assigns of
                                such legal entity/ person.
                            </span>
                        </p>
                        <p>
                            <strong>1.5. “Company”</strong>
                            <span>
                                shall have the same meaning as ascribed to the
                                term within the applicable Insertion Order and,
                                unless repugnant to the context or meaning
                                thereof, shall mean and include the
                                successors-in-interest and permitted assigns of
                                such legal entity/ person, along with any Client
                                that has authorised Company to enter into the
                                Insertion Order with Billiontags on behalf of
                                Client.
                            </span>
                        </p>
                        <p>
                            <strong>1.6. “Confidential Information”</strong>
                            <span>
                                means any information that is disclosed,
                                provided or made accessible by, or on behalf of,
                                one Party to the other Party in connection with
                                these Online T&amp;Cs and which is identified as
                                ‘confidential' or ‘proprietary' or which, given
                                the nature of the information or material, or
                                the circumstances surrounding the disclosure or
                                provision, reasonably should be understood to be
                                confidential or proprietary (e.g. product or
                                business plans), but does not include
                                information that the recipient already knew,
                                becomes public through no fault of the
                                recipient, or was independently developed by the
                                recipient without reference to the discloser's
                                confidential information.
                            </span>
                        </p>
                        <p>
                            <strong>1.7. “Effective Date” </strong>
                            <span>
                                shall have the same meaning as ascribed to the
                                term within the applicable Insertion Order.
                            </span>
                        </p>
                        <p>
                            <strong>1.8. “Insertion Order”</strong>
                            <span>
                                means the applicable insertion order signed by
                                the authorised representatives of the Company
                                and Billiontags as of the Effective Date.
                            </span>
                        </p>
                        <p>
                            <strong>1.9. “Billiontags” </strong>
                            <span>
                                means Billiontags Enterprises FZCO and, unless
                                repugnant to the context or meaning thereof,
                                shall mean and include its representatives,
                                successors-in-interest and assigns.
                            </span>
                        </p>
                        <p>
                            <strong>1.10. “Net Earnings” </strong>
                            <span>
                                means the total sum paid by the Company to
                                Billiontags minus any flowthrough revenue, costs
                                or commissions payable by Billiontags to any
                                third parties, including but not limited to any
                                search, social and programmatic platforms such
                                as Google, Meta, LinkedIn and TikTok.
                            </span>
                        </p>
                        <p>
                            <strong>1.11. “Services” </strong>
                            <span>
                                means the purchase of Ad Inventory and the
                                deployment of Creatives, all based on the
                                Company's instructions, along with the provision
                                of analytics and reports on campaign execution
                                from time to time, as expressly stipulated
                                within each applicable Insertion Order.
                            </span>
                        </p>
                        <p>
                            <strong>1.12. “Sites” </strong>
                            <span>
                                means the digital properties on which a Creative
                                is deployed (including, but not limited to, web
                                and mobile sites, video players, mobile
                                applications and third-party platforms such as
                                Google AdWords and Meta Marketing.
                            </span>
                        </p>
                        <p>
                            <strong>1.13. “Subcontractor”</strong>
                            <span>
                                means, with respect to a Party, a subcontractor,
                                consultant third-party service provider or agent
                                engaged by such Party (or a Client or Affiliate
                                of such Party) in connection with its use or
                                provision of Services, both of which are
                                permitted under these Online T&amp;Cs and the
                                Insertion Orders.
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong> SERVICES</strong>
                        <p>
                            <span>
                                Billiontags will provide the Services to the
                                Company stipulated within the applicable
                                Insertion Order. It is clarified that the
                                Company can enter into Insertion Orders with
                                Billiontags on behalf of the Client as the
                                Client's agent. Each Insertion Order, which will
                                be deemed to incorporate these Online T&amp;Cs,
                                will contain additional terms and conditions
                                that are specific to the Service(s) that is
                                provided pursuant to such Insertion Order.
                                Services are provided subject to the terms and
                                conditions of these Online T&amp;Cs (including,
                                for purposes of clarification, the Insertion
                                Orders) and any applicable third-party terms and
                                conditions. It is expressly clarified that
                                unless otherwise specifically agreed to in
                                writing between us within the relevant Insertion
                                Order, the Company shall bear the responsibility
                                of adhering to the terms and conditions of
                                applicable third parties, including any
                                third-party media buying platforms that may be
                                utilised by Billiontags to render its services
                                hereunder.
                            </span>
                        </p>
                    </li>
                    <li>
                        <strong> PAYMENT; PAYMENT OBLIGATIONS</strong>
                        <p>
                            <strong>3.1 Invoicing. </strong>
                        </p>
                        <p>
                            <span>
                                Billiontags will invoice on a calendar-month
                                basis and send the invoice upon completion of
                                the first month's delivery or within 30 days of
                                completion of the IO, whichever is earlier.
                                Invoices will be sent to the Agency's billing
                                address on the IO. They will include information
                                specified by the Agency, such as the IO number,
                                Advertiser name, brand name or campaign name,
                                and any number or other identifiable reference
                                stated as required for invoicing on the IO.
                            </span>
                        </p>
                        <p>
                            <strong>3.2 Payment Dates and Terms. </strong>
                        </p>
                        <p>
                            <span>
                                Payment dates and related terms for each
                                particular Service shall be set forth in the
                                applicable Insertion Order.
                            </span>
                        </p>
                        <p>
                            <strong>3.3 Additional Payment Rules. </strong>
                        </p>
                        <ol>
                            <li>
                                <span>
                                    a) Any additional fees other than the media
                                    cost or the data cost provided by the
                                    applicable third-party platform are not
                                    included. The addition of any third-party
                                    Creative/ Rich Media vendors may increase
                                    the cost. Any additional verification
                                    measurement (including, but not limited to,
                                    IVT, viewability and demo) will lead to
                                    additional costs payable by the Company.
                                    VAST &amp; 1×1 tags from Industry-approved
                                    vendors are acceptable. Any other
                                    third-party vendor requests will have to be
                                    agreed to in writing between Billiontags and
                                    the Company in advance in respect of the
                                    specific use case.
                                </span>
                                <span>
                                    <br />
                                </span>
                                <span>
                                    b) Late payments that are not disputed in
                                    good faith bear interest at the rate of 1.5%
                                    per month (or the highest rate permitted by
                                    law, if less). Charges are exclusive of
                                    taxes. The amounts invoiced hereunder do not
                                    and will not include any taxes levied by or
                                    due to any duly authorised taxing authority.
                                    Company will pay all applicable taxes and
                                    other government charges, if any, however
                                    designated, derived from or imposed on the
                                    transactions contemplated hereby, including
                                    without limitation sales, value-added, use,
                                    and transfer, withholding privilege, excise
                                    and other taxes and duties. Company will pay
                                    reasonable expenses and outside attorneys'
                                    fees Billiontags incurs in collecting late
                                    payments that are not disputed in good
                                    faith.
                                </span>
                                <span>
                                    <br />
                                </span>
                                <span>
                                    c) Notwithstanding anything to the contrary
                                    in these Online T&amp;Cs, the Company will
                                    be ultimately responsible for any and all
                                    payment obligations for its purchases of Ad
                                    Inventory through the Services.
                                </span>
                                <span></span>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>DUTIES AND OBLIGATIONS OF THE COMPANY</strong>
                        <p>
                            <strong>4.1 Prohibited Acts</strong>
                        </p>
                        <p>
                            <strong>a) </strong>
                            <span>
                                General: The Company will not, will not attempt
                                to, and will not assist or knowingly permit any
                                third party to:
                            </span>
                            <br />
                            <span>
                                i. pass personally identifiable information to
                                Billiontags; or
                            </span>
                            <br />
                            <span>
                                ii. alter or tamper with any information or
                                materials on or associated with any Service.
                            </span>
                        </p>
                        <p>
                            <strong>b) </strong>
                            <span>
                                The Company hereby further covenants that
                            </span>
                            <br />
                            <span>
                                i. None of the Creatives provided to Billiontags
                                will, when viewed or clicked on by a user(s),
                                cause the download or delivery of any software
                                application executable code, virus, or malicious
                                or social engineering (phishing) code or
                                features; and
                            </span>
                            <br />
                            <span>
                                ii. none of the Creatives provided to
                                Billiontags, and none of the sites to which a
                                user is directed following a click on any such
                                Ad Unit, will be obscene, deceptive or otherwise
                                illegal
                            </span>
                        </p>
                        <p>
                            <span>
                                Billiontags may reject, remove or deactivate
                                Creatives that do not comply with policies or do
                                not comply with any applicable law, rule or
                                regulation or for any reasonable business
                                reason.
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong>
                            COMPANY'S REPRESENTATIONS AND WARRANTIES
                        </strong>
                        <p>
                            <strong>5.1 </strong>
                            <span>
                                Company hereby represents and warrants that it
                                has and will have all necessary rights and
                                authority (a) to enter into these Online
                                T&amp;Cs and each Insertion Order and (b) to
                                perform its obligations hereunder and
                                thereunder; and
                            </span>
                        </p>
                        <p>
                            <strong>5.2 </strong>
                            <span>
                                Company will (a) include within each applicable
                                Insertion Order all reasonably applicable
                                requirements to deliver the Services in
                                compliance with Company's other agreements, (b)
                                have obtained and be deemed to have hereby
                                granted to Billiontags all rights necessary to
                                allow Billiontags to store, audit, optimise and
                                serve Creatives and otherwise provide the
                                Services hereunder.
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong>
                            Billiontags's REPRESENTATIONS AND WARRANTIES
                        </strong>
                        <p>
                            <strong>6.1 </strong>
                            <span>
                                Billiontags hereby represents and warrants that
                                it has and will have all necessary rights and
                                authority (a) to enter into these Online
                                T&amp;Cs and each Insertion Order and (b) to
                                perform its obligations hereunder and
                                thereunder.
                            </span>
                        </p>
                        <p>
                            <strong>6.2 </strong>
                            <span>
                                Billiontags will (a) provide Services to the
                                Company in accordance with the terms set out
                                herein and each Insertion Order, and (b) use
                                commercially reasonable efforts to serve
                                Creatives to Ad Inventory. Notwithstanding
                                anything to the contrary in these Online
                                T&amp;Cs, Billiontags will have no liability or
                                other responsibility whatsoever with respect to
                                the content of any Creative or any Ad Inventory
                                or Site.
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong> TERM, TERMINATION &amp; SUSPENSION</strong>
                        <p>
                            <span>
                                Unless earlier terminated, these Online T&amp;Cs
                                will remain in effect until all Insertion Orders
                                have been terminated. Each Party may terminate
                                an Insertion Order immediately on notice to the
                                other Party that it is in material breach of
                                these Online T&amp;Cs with respect to such
                                Service, provided that (a) if the breach is
                                capable of cure, the breaching Party will have
                                30 days from the notice date to cure the breach
                                to the non-breaching Party's reasonable
                                satisfaction; and (b) Billiontags may
                                immediately suspend delivery of any or all
                                Services under the Insertion Orders on notice to
                                Company if, in Billiontags's reasonable
                                discretion, Company breaches any of the clauses
                                of Section 4 hereof. If the Company fails to pay
                                fees invoiced by Billiontags (other than fees
                                disputed in good faith) by the applicable
                                payment due date, Billiontags may suspend each
                                applicable Service or terminate the applicable
                                Insertion Order, in whole or in part, on notice
                                to the Company. Notwithstanding termination of
                                these Online T&amp;Cs, any provisions of these
                                Online T&amp;Cs that by their nature are
                                intended to survive will survive termination.
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong> DATA, PROPRIETARY RIGHTS</strong>
                        <p>
                            <p>
                                <strong>8.1 Data. </strong>
                                <strong>
                                    <br />
                                </strong>
                                <span>
                                    As between Company and Billiontags and
                                    subject to terms and conditions of these
                                    Online T&amp;Cs (including, without
                                    limitation, Section 4.1 hereof), all data
                                    derived from transactions (i.e., purchases
                                    and sales of Ad Inventory and the serving of
                                    Creatives to such Ad Inventory) conducted
                                    pursuant to its use of Services
                                    (collectively, “Company Data”) shall belong
                                    to Company; provided that Billiontags may
                                    use and disclose Company Data solely:
                                </span>
                            </p>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>a) </strong>
                                <span> as aggregate Service statistics,</span>
                            </p>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>b) </strong>
                                <span>
                                    to provide the Services it being understood
                                    and agreed that without limiting the
                                    generality of the foregoing, Billiontags may
                                    disclose certain Company Data, in connection
                                    with the operation of the Services, to third
                                    parties from which Billiontags has purchased
                                    Ad Inventory under an Insertion Order
                                </span>
                            </p>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>c) </strong>
                                <span>
                                    to (a) Subcontractors and other third-party
                                    service providers (e.g. auditors) of
                                    Billiontags who need to know it and who are
                                    obligated to keep it confidential subject to
                                    the terms and conditions hereto and (b)
                                    third parties whose services Company has
                                    elected to use though the applicable
                                    Service(s) and who are obligated to keep it
                                    confidential subject to the terms and
                                    conditions hereto,
                                </span>
                            </p>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>d) </strong>
                                <span>
                                    if and as required by court order, law or
                                    governmental or regulatory agency (after, if
                                    permitted, giving reasonable notice to
                                    Company and using commercially reasonable
                                    efforts to provide Company with the
                                    opportunity to seek a protective order or
                                    the equivalent (at Company's expense), and
                                </span>
                            </p>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>e) </strong>
                                <span>
                                    as may otherwise be elected by Company
                                    within the applicable Service(s); provided
                                    further, however, that Billiontags's
                                    retrieval and/or provision to Company of
                                    event-level data and/or custom reports
                                    derived from Company's use of Services may
                                    result in additional fees hereunder (to be
                                    agreed by Billiontags and Company in writing
                                    through an Insertion Order) based on storage
                                    and service costs. For purposes hereof,
                                    notwithstanding anything herein to the
                                    contrary, but subject to the provisos in
                                    this Section 10.1, Company Data will be
                                    Confidential Information of the Company.
                                </span>
                            </p>
                        </p>
                        <p>
                            <strong>8.2 Proprietary Rights. </strong>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>a) </strong>
                                <span>
                                    As between the Parties, Billiontags owns and
                                    will retain all rights, title and interest
                                    in and to each of the Services (in each
                                    case, including but not limited to all
                                    concepts, methodologies, techniques, models,
                                    templates, trade secrets, processes,
                                    information, materials and know-how
                                    contained therein, all modifications,
                                    updates, enhancements and derivative works
                                    thereof, all documentation and manuals,
                                    related thereto and all other aspects of
                                    such technology, the name “Billiontags” or
                                    any derivatives thereof and any other
                                    trademarks and logos which are owned or
                                    controlled by Billiontags and made available
                                    to Company through the Services or otherwise
                                    hereunder and all intellectual property and
                                    proprietary rights in and to all of the
                                    foregoing. As between the Parties, the
                                    Company owns and will retain all rights,
                                    title and interest in and to its
                                    intellectual property, including any
                                    modifications, updates, enhancements and
                                    derivative works thereof.
                                </span>
                            </p>
                            <p style={{ paddingLeft: "30px" }}>
                                <strong>b) </strong>
                                <span>
                                    Each Party reserves any and all rights not
                                    expressly granted in these Online T&amp;Cs
                                    and disclaims all implied licenses,
                                    including without limitation implied
                                    licenses to trademarks, copyrights, trade
                                    secrets and patents.
                                </span>
                            </p>
                        </p>
                    </li>

                    <li>
                        <strong> DISCLAIMERS; LIMITATION OF LIABILITY</strong>
                        <p>
                            <strong>9.1. </strong>
                            <span>
                                Each party disclaims all implied and statutory
                                warranties, including without limitation implied
                                warranties for non-infringement (It being
                                understood that, for purposes of clarification,
                                the foregoing will not limit either party's IP
                                infringement obligation outlined in Section 12
                                (Indemnification) of these online T&amp;Cs),
                                merchantability and fitness for any purpose. 
                            </span>
                        </p>
                        <p>
                            <strong>9.2. </strong>
                            <span>
                                Notwithstanding anything to the contrary in
                                these online T&amp;Cs, the applicable insertion
                                order or any other agreement/document signed
                                between the parties regarding the subject matter
                                of these online T&amp;Cs or the applicable
                                insertion order (whether prior to or subsequent
                                to the applicable insertion order), to the
                                fullest extent permitted by law regardless of
                                the theory or type of claim: (a) Except (i) for
                                indemnification amount payable to third parties
                                under these online T&amp;Cs and (ii) with
                                respect to breaches of section 8 (
                                Confidentiality) of these online T&amp;Cs, No
                                party may be held liable under these online
                                T&amp;Cs, No party may be held liable under
                                these online T&amp;Cs or arising out of or
                                related to performance of these online T&amp;Cs
                                for any indirect, incidental, consequential,
                                special, punitive or exemplary damages, even if
                                the party is aware or should know that such
                                damages are possible; and (b) except with
                                respect to (i) its fraud or intentional
                                misconduct and (ii) It's payment obligations
                                under these online T&amp;Cs, Billiontags's
                                maximum aggregate liability with respect to a
                                particular insertion order will not exceed the
                                total amount of Net earnings paid by company to
                                Billiontags with respect to services under such
                                insertion order during the six(6) months before
                                the date when the liability arose. Unless
                                otherwise agreed to by the parties in writing,
                                the foregoing limitation of liability shall
                                apply regardless of the cause of action under
                                which such damages are sought. 
                            </span>
                        </p>
                    </li>
                    <li>
                        <strong> INDEMNITY</strong>
                        <p>
                            <strong>10.1</strong>
                            <span>
                                Each Party (in such capacity, the “Indemnitor”)
                                will defend, indemnify and hold harmless the
                                other Party and its officers, directors,
                                employees and agents (each, an “Indemnitee”)
                                from all third party claims or liabilities and
                                related expenses (including without limitation
                                reimbursement for reasonable outside attorneys'
                                fees and disbursements) arising out of or
                                related to (i) the Indemnitor's breach or
                                alleged breach of these Online T&amp;Cs
                                (including, for purposes of clarification, any
                                Insertion Order), (ii) the Indemnitor's breach
                                or alleged breach of applicable law, (iii)
                                Billiontags making available Creatives provided
                                to Billiontags by either the Company, its
                                Subcontractor or any third party introduced by
                                the Company to run any advertising campaign
                                hereunder, in accordance with the instructions
                                of the Company or any third party introduced by
                                the Company, or (iv) the infringement or
                                misappropriation of a third party's patent,
                                trademark, trade secret copyright or any other
                                intellectual property right in connection with
                                the Creative, technology, data or other
                                materials provided by Company, its Subcontractor
                                or any third party introduced by the Company to
                                Billiontags for utilization by Billiontags in
                                connection with the Services hereunder.
                            </span>
                        </p>
                        <p>
                            <strong>10.2</strong>
                            <span>
                                The Indemnitee must (i) promptly notify the
                                Indemnitor in writing of the third-party claims
                                (provided that failure of the Indemnitee to
                                promptly notify the Indemnitor will not relieve
                                the Indemnitor of its indemnification
                                obligations, except to the extent it has been
                                damaged by the failure); (ii) reasonably
                                cooperate with the Indemnitor in the defence of
                                the matter and (iii) give the Indemnitor primary
                                control of the defence of the matter and
                                negotiations for its settlement. The Indemnitee
                                may, at its expense, join in the defence with
                                counsel of its choice. The Indemnitor may enter
                                into a settlement only if it (A) involves only
                                the payment of money damages by the Indemnitor
                                and (B) includes a complete release of the
                                Indemnitee; any other settlement will be subject
                                to the written consent of the Indemnitee (not to
                                be unreasonably withheld or delayed).
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong> PRIVACY</strong>
                        <p>
                            <span>
                                The company will ensure compliance with all
                                applicable privacy laws, rules and regulations.
                                Company will ensure that each of its Sites
                                contains, and will advise in writing each of its
                                clients that each of their Sites is required to
                                contain: (x) a privacy policy that (a) discloses
                                (i) the usage of third-party technology and (ii)
                                the data collection and usage resulting from the
                                Service (it being understood that this clause
                                (a) will not be deemed to require those privacy
                                policies to expressly identify Billiontags or
                                any Service, unless otherwise required by law,
                                rule or regulation), (b) contains a conspicuous
                                live hyperlink to an opt-out web site that
                                provides the user the ability to opt out of
                                interest- based advertising through the
                                Services, if data is collected on such Site(s)
                                through the Service for purposes of
                                interest-based advertising, and (c) complies
                                with all applicable privacy laws, rules and
                                regulations; and (y) to the extent required by
                                applicable law, rule or regulation, a mechanism
                                to obtain, with respect to the use of
                                Billiontags's Services, users' prior and
                                informed consent to the usage of third-party
                                technology (reasonable evidence of such consent
                                to be maintained to the extent so required).
                            </span>
                        </p>
                    </li>
                    <li>
                        <strong> NON- SOLICITATION</strong>
                        <p>
                            <span>
                                During the term of the Agreement and for a
                                period of one (1) year thereafter, neither Party
                                shall (either directly or indirectly through a
                                third party) employ, solicit to employ, cause to
                                be solicited for the purpose of employment or
                                offer employment to any employee, consultant or
                                Subcontractor of the other Party who are
                                connected with the Services to be provided under
                                this Agreement or aid any third person to do so,
                                without the specific written consent of the
                                other Party.
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong> DISCLAIMER</strong>
                        <p>
                            <span>
                                Except for the express warranties as stated in
                                this section 14, Billiontags disclaims all other
                                warranties, whether implied by operation of law
                                or otherwise, including, without limitation, any
                                implied warranties of merchantability and
                                fitness for a particular purpose. The services
                                are provided “as is”. Billiontags does not
                                warrant that the services provided will operate
                                uninterrupted or error-free. Billiontags and its
                                licensors are not responsible for any content
                                inside any creatives served. Billiontags makes
                                no representation or warranty regarding the
                                results the company will obtain by using the
                                services. 
                            </span>
                        </p>
                    </li>

                    <li>
                        <strong> MISCELLANEOUS</strong>
                        <p>
                            <strong>14.1 </strong>
                            <strong>Headings</strong>
                            <strong>. </strong>
                            <span>
                                The section headings used in these Online
                                T&amp;Cs are intended for reference purposes
                                only and shall not affect the interpretation of
                                these Online T&amp;Cs.
                            </span>
                        </p>
                        <p>
                            <strong>14.2 </strong>
                            <strong>Counterparts</strong>
                            <strong>. </strong>
                            <span>
                                These Online T&amp;Cs may be executed in
                                counterparts (which may be exchanged by
                                facsimile), each of which shall be deemed an
                                original but shall constitute the same
                                instrument.
                            </span>
                        </p>
                        <p>
                            <strong>14.3 </strong>
                            <strong>Waiver</strong>
                            <strong>. </strong>
                            <span>
                                No failure on the part of any Party to exercise,
                                and no delay in exercising, any right, power or
                                remedy under these Online T&amp;Cs shall operate
                                as a waiver thereof.
                            </span>
                        </p>
                        <p>
                            <strong>14.4 </strong>
                            <strong>Cumulative Remedies</strong>
                            <strong>. </strong>
                            <span>
                                Except as expressly set forth herein, no remedy
                                hereunder is intended to be exclusive of any
                                other remedy available hereunder or at law or in
                                equity.
                            </span>
                        </p>
                        <p>
                            <strong>14.5 </strong>
                            <strong>Severability</strong>
                            <strong>. </strong>
                            <span>
                                If and to the extent any provision of these
                                Online T&amp;Cs is held invalid or unenforceable
                                at law, such provision shall be modified so as
                                to have the meaning as close as legally possible
                                to the terms therein or (if modification is not
                                possible) stricken from the Online T&amp;Cs, and
                                the remainder of the Online T&amp;Cs shall
                                continue in full effect and be enforceable to
                                the fullest extent permitted by law.
                            </span>
                        </p>
                        <p>
                            <strong>14.6 </strong>
                            <strong>Relationship</strong>
                            <strong>. </strong>
                            <span>
                                Billiontags is an independent contractor, and
                                these Online T&amp;Cs does not create an agency,
                                partnership, or joint venture relationship
                                between Billiontags and Company / any Company
                                personnel.
                            </span>
                        </p>
                        <p>
                            <strong>14.7 Force Majeure. </strong>
                            <span>
                                Neither Party shall be liable for any failure or
                                delay in the performance of any of their
                                respective obligations if prevented from doing
                                so by a Force Majeure Event. “Force Majeure
                                Event” means (i) floods, earthquakes, or other
                                similar elements of nature or acts of God; (ii)
                                riots, civil disorders, rebellions or
                                revolutions in any country; or (iii) any other
                                cause beyond the reasonable control of the
                                non-performing Party, provided the
                                non-performing Party is without fault in failing
                                to prevent or causing such default or delay, and
                                such default or delay could not have been
                                prevented or circumvented by the non-performing
                                Party through the reasonable use of alternate
                                sources, workaround plans or other reasonable
                                precautions.
                            </span>
                        </p>
                        <p>
                            <strong>14.8 </strong>
                            <strong>Notices</strong>
                            <strong>. </strong>
                            <span>
                                Unless otherwise stated, all notices, approvals,
                                instructions and other communications for the
                                purposes of these Online T&amp;Cs shall be given
                                in writing and may be given by personal delivery
                                or by sending the same by registered post or
                                electronic medium addressed to the Party
                                concerned at the address stated herein, or any
                                other address subsequently notified to the other
                                Parties for the purposes of this Clause and
                                shall be deemed to be effective in the case of
                                personal delivery or delivery by registered post
                                at the time of delivery.
                            </span>
                        </p>
                        <p>
                            <strong>14.9 </strong>
                            <strong>Publicity</strong>
                            <strong>. </strong>
                            <span>
                                Either Party grants the other the limited right
                                to use the other's name and any trademarks or
                                service marks in connection with these Online
                                T&amp;Cs. Such use of the other party's name and
                                trademarks shall be solely to identify the other
                                as business partners under these Online
                                T&amp;Cs. Sufficient care will be taken to
                                ensure that neither Parties deem to explicitly
                                or implicitly endorse the activities of the
                                other or which is likely to cause confusion as
                                to the Company's relationship to Billiontags's
                                services. Both Parties shall allow the other to
                                use its name as a reference.
                            </span>
                        </p>
                        <p>
                            <strong>14.10 </strong>
                            <strong>Marketing Materials</strong>
                            <strong>. </strong>
                            <span>
                                Both Parties agree to use any marketing
                                materials provided by the other party only for
                                the purpose of marketing the Services detailed
                                in these Online T&amp;Cs and its Insertion
                                Orders. Neither Party shall use such provided
                                marketing materials in any manner not authorised
                                by the other Party.
                            </span>
                        </p>
                        <p>
                            <strong>14.11 </strong>
                            <strong>Governing Law / Jurisdiction</strong>
                            <strong>. </strong>
                            <span>
                                These Online T&amp;Cs, and all matters arising
                                directly or indirectly from these Online
                                T&amp;Cs, shall be governed by and construed in
                                accordance with the laws of the UAE, without
                                regard to its conflict of laws rules applicable
                                to contracts to be performed entirely within
                                Dubai, UAE. For all such matters, each Party,
                                subject to the provisions of the below
                                paragraph, irrevocably submits to the exclusive
                                jurisdiction of the state and federal courts
                                located in Dubai, UAE and waives any
                                jurisdictional, venue, or inconvenient forum
                                objections to such courts.
                            </span>
                        </p>
                        <p>
                            <strong>14.12 </strong>
                            <strong>Survival</strong>
                            <strong>. </strong>
                            <span>
                                The obligations under sections that contemplate
                                performance or observance subsequent to
                                termination or expiration of these Online
                                T&amp;Cs, and any other sections that state that
                                they are to survive expiration or termination,
                                shall survive the expiration or termination of
                                these Online T&amp;Cs, including the following
                                sections: Section 5 (“Representations and
                                Warranties”), Section 9 (“Disclaimer &amp;
                                Limitation of Liability”), Section 10
                                (Indemnity), Section 11 (Privacy), Section 12
                                (Non-Solicitation) and Section 16
                                (“Miscellaneous”).
                            </span>
                        </p>
                        <p>
                            <strong>14.13 </strong>
                            <strong>Entire Online T&amp;Cs Agreement</strong>
                            <strong>. </strong>
                            <span>
                                These Online T&amp;Cs and the applicable
                                Insertion Order constitute the entire agreement
                                of the Parties with respect to the subject
                                matter hereof and supersedes any and all
                                existing or prior agreements and communications,
                                whether written or oral, relating to the subject
                                matter hereof. No modification of these Online
                                T&amp;Cs shall be effective unless it is in
                                writing and signed by an authorised
                                representative of each Party.
                            </span>
                        </p>
                        <p>
                            <span>
                                <br />
                            </span>
                            <strong>14.14 </strong>
                            <strong>Precedence</strong>
                            <strong>. </strong>
                            <span>
                                In case of any contradiction between any other
                                agreement signed by the parties and these Online
                                T&amp;Cs, these Online T&amp;Cs will take
                                precedence.
                            </span>
                        </p>
                    </li>
                </ol>
            </div>
        </Layout>
    )
}

export default Terms
